import { StyleValue } from 'vue';
import { IGetRowShadowValues } from '../models/table.model';
import { ElementPositionUtil } from './getElementPositionOnPage';

export class TableUtils {
  public static getRowShadowValues(rowIndex: number, elementRefs: Array<HTMLElement>): IGetRowShadowValues | undefined {
    const element = elementRefs?.[rowIndex];
    if (!element) {
      return;
    }

    const positionElement = ElementPositionUtil
      .getElementPositionInsideRelativeParent(element);
    if (!positionElement) {
      return;
    }

    return {
      height: element?.offsetHeight,
      top: positionElement?.top,
      left: positionElement?.left,
      width: element?.offsetWidth,
    };
  }

  public static mapRowShadowValuesToStyle(values?: IGetRowShadowValues): StyleValue {
    return {
      '--row-height': `${values?.height ?? 0}px`,
      '--row-top': `${values?.top ?? 0}px`,
      '--row-left': `${values?.left ?? 0}px`,
      '--row-width': `${values?.width ?? 0}px`,
    };
  }

  public static getRowShadowStyle(rowIndex: number, elementRefs: Array<HTMLElement>): StyleValue {
    return TableUtils.mapRowShadowValuesToStyle(
      TableUtils.getRowShadowValues(rowIndex, elementRefs),
    );
  }
}
